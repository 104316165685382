/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts'
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

const Chartc = (props) => {

  var LineData = []
  var LineChartOptionsPlot = []

  const [date, setDate] = useState(null);
  const [LineChartOptions, LineChartOptionsSet] = useState([])
  const [LinePlotData, LinePlotDataSet] = useState([]);
  const [actualDataUpto, setActualDataUpto] = useState(null);
  const [modelComputedOn, setModelComputedOn] = useState(null);
  const [flag, setFlag] = useState(false);

  const convertDate = (date) => {

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var temp_date = date.split("/");
    return temp_date[1] + " " + months[Number(temp_date[0]) - 1] + " " + temp_date[2];
  }


  useEffect(() => {

    axios({
      method: "get",
      url: props.url,
      headers: {},
    }).then(async (response) => {
      console.log('response', response.data.data)
      if (response.data.status === "success" && response.data.data) {
        LineData.push(["Date", "Actual", "Predicted"])



        LineChartOptionsPlot = {

          options: {
            zoom: true
          },
          hAxis: {
            title: 'Date',
            titleTextStyle: {
              color: 'red',
              fontSize: 16,
              bold: true

            },
            format: 'MMM dd, yyyy',
            // minValue: new Date(2022, 1, 1),
            textStyle: {
              fontSize: 12,
            },
            viewWindow: {
              min: date ? new Date(2021, 11, 1) : new Date(2021, 11, 1),
            }
          },

          interpolateNulls: false,
          vAxis: {

            title: 'Infections',
            slantedText: true,
            slantedTextAngle: 90,
            titleTextStyle: {
              color: 'red',
              fontSize: 16,
              bold: true,
            },
            min: 0,
            textStyle: {

              rotateText: 90,
              fontSize: 12
            }
          },
          series: {
            1: {
              color: 'red', curveType: 'Data'
            },
            2: { color: 'green', curveType: 'Data' },
          },




          is3D: 'true',
          sliceVisibilityThreshold: 1 / 20,
          chartArea: { width: '80%', height: '70%' },
          explorer: { actions: ['dragToZoom', 'rightClickToReset'] },
          legend: {
            position: 'bottom', textStyle: { color: 'black', fontSize: 16 }
          },
        }

        const plotDataArray = response.data.data.plotData.data;
        setFlag(plotDataArray.length > 0)
        for (let index = 0; index < plotDataArray.length - 1; index++) {
          const element = plotDataArray[index];
          LineData.push([
            new Date(element.date),
            (!element.actualData) ? null : parseInt(element.actualData),
            (!element.predictedData) ? null : parseInt(element.predictedData)
          ])
        }
        LinePlotDataSet(LineData);
        setActualDataUpto(convertDate(response.data.data.plotData.actualDataUpto));
        setModelComputedOn(convertDate(response.data.data.plotData.modelComputedOn));
        setDate(new Date(2021, 11, 1));
        LineChartOptionsSet(LineChartOptionsPlot)

      }

    })
  }, [props.url]);



  return (
    <div className="mt-10">
      <h3>
        Detected New Infections ( 7 day average )  </h3>

      {flag ?
        <Chart
          width={'100%'}
          height={'500px'}
          chartType="LineChart"
          loader={<div>Fetching data from backend... <Spinner animation="border" variant="info" size="bg" /> </div>}
          data={LinePlotData}
          titleTextStyle={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}
          options={LineChartOptions}
          rootProps={{ 'data-testid': '2' }}
        /> : <div>Fetching data from backend... <Spinner animation="border" variant="info" size="bg" /> </div>}

      <br></br>
      {flag ? <div>
        <Button variant="warning" onClick={() => {

          setDate(new Date(2020, 4, 1));

          var LineChartOptionsPlot = {

            options: {
              zoom: true
            },
            hAxis: {
              title: 'Date',
              titleTextStyle: {
                color: 'red',
                fontSize: 16,
                bold: true

              },
              format: 'MMM dd, yyyy',
              textStyle: {
                fontSize: 12,
              },
              viewWindow: {
                min: date ? new Date(2020, 3, 1) : new Date(2020, 3, 1),
              }
            },

            interpolateNulls: false,
            vAxis: {
              title: 'Infections',
              titleTextStyle: {
                color: 'red',
                fontSize: 16,
                bold: true,
              },
              min: 0,
              textStyle: {
                rotateText: 90,
                fontSize: 12
              }
            },
            series: {
              1: {
                color: 'red', curveType: 'Data'
              },
              2: { color: 'green', curveType: 'Data' },
            },




            is3D: 'true',
            sliceVisibilityThreshold: 1 / 20,
            chartArea: { width: '80%', height: '70%' },
            explorer: { actions: ['dragToZoom', 'rightClickToReset'] },
            legend: {
              position: 'bottom', textStyle: { color: 'black', fontSize: 16 }
            },
          }
          console.log("LineChartOptionsPlot", LineChartOptionsPlot)
          console.log("Date", date)
          LineChartOptionsSet(LineChartOptionsPlot)

        }}>Show whole Data</Button>{' '}

        <br></br>
        <br></br>
        <div> select area on the chart to zoom on it and right click to get back the default view </div>
        <br></br>
        {(actualDataUpto) ? <p style={{ textAlign: "center" }} ><i>Actual data updated upto:  {actualDataUpto}</i> </p> : <div />}
        {(modelComputedOn) ? <p style={{ textAlign: "center" }} ><i>Model projections computed on: {modelComputedOn}</i> </p> : <div />}
      </div> :
        null}
      <p style={{ textAlign: "center" }} ><i> Accuracy of projections is within &#177; 10%</i> </p>
    </div>
  )

}
export default Chartc;


