import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";


const Dropdown = (props) => {
  const [options, setOptions] = useState([])
  const [state, setState] = useState(null);
  // const [showDistrictDropdown, setShowDistrictDropdown] = useState(false)
  // const [currentDistrict, setCurrentDistrict] = useState(null);
  // const [fetchDistrict, setFetchDistrict] = useState(0)
  // const [districtOptions, setDistrictOptions] = useState([])
  useEffect(() => {
    axios.get('https://api.sutra-india.in/api/location/states?plottable=true')
      .then((response) => {
        // console.log(response)
        setOptions(response.data.data)
      });
  }, []);
  const onchangeSelectState = (item) => {
    props.chooseRegionFunction(item)
    setState(item);
    // setShowDistrictDropdown(true)
    // setFetchDistrict(fetchDistrict + 1)
    // // setCurrentDistrict(null)
    // fetch(
    //   `https://api.sutra-india.in/api/location/states?stateId=${item.state_id}&plottable=true`
    // ).then(response => response.json())
    //   .then(data => {
    //     setDistrictOptions(data.data)
    //   });
  };
  // const onchangeSelectDistrict = (item) => {
  //   // console.log(item)
  //   props.chooseRegionFunction(item)
  //   setCurrentDistrict(item);
  // };
  function onClickIndiaButton(){
    setState(null);
    // setCurrentDistrict(null)
    // setDistrictOptions([]);
    // setShowDistrictDropdown(false);
    props.showIndiaChartFunction(true)
  }
  // console.log("CURRENT DISTRICT",currentDistrict, "showDistrictDropdown",showDistrictDropdown)
  return (
    <div className="container content" style={{testAlign:"center"}}>
      <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-4 mt-3"  style={{ margin: "0 auto"}}>
        <button onClick={()=>{onClickIndiaButton()}} className="btn btn-outline-primary  " style={{ width:"100%" }}>India</button>
      </div>
        <form className="mt-3" >
          <div className="form-group row" >
            <div className="col-lg-4 col-md-4 col-sm-4" style={{ margin: "0 auto", zIndex: "100" }} >
            <Select
                value={state}
                onChange={onchangeSelectState}
                options={options}
                getOptionValue={(option) => option.state_id}
                getOptionLabel={(option) => option.state_name}
                placeholder="Select State"
              />
            </div>
            {/* {(showDistrictDropdown) ? (
              <div className="row" style={{margin:"0px", padding:"0px", marginBottom:"7px"}}>
                 <div className="col-lg-4 col-md-4 col-sm-4 mt-3" style={{ margin:"0 auto", zIndex: "99"}} >
                <Select
                  value={currentDistrict}
                  onChange={onchangeSelectDistrict}
                  options={districtOptions}
                  getOptionValue={(option) => option.district_id}
                  getOptionLabel={(option) => option.district_name}
                  placeholder="Select District"
                />
              </div>
              </div>
              ) : null} */}
          </div>
        </form>
        </div>
    </div>
  );
};
export default Dropdown;
