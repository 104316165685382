/* eslint-disable */

import React, {useState} from 'react';
import Footer from './common/footer/footer';
import SideNav from './common/sidenav/sidenav';
import TopNav from './common/topnav/topnav';
import Chart from './common/chart/chart';
import Dropdown from './dropdown';



const Landing = () => {
  const [showIndiaChart, setShowIndiaChart] = useState(true);
  const [showStateChart, setShowStateChart] = useState(false);
  const [showDistrictChart, setShowDistrictChart] = useState(false);
  const [district, setDistrict] = useState("Kanpur");
  const [districtId, setDistrictId] = useState(null);
  const [state, setState] = useState("Uttar Pradesh");
  const [stateId, setStateId] = useState(null);
  const [maxY, setMaxY] = useState(null);
    
  
  function chooseRegion(data){
    if("state_id" in data){
      console.log(data)
      setShowIndiaChart(false);
      setShowDistrictChart(false);
      setState(data.state_name);
      setStateId(data.state_id);
      setShowStateChart(true);
    }else if("district_id" in data){
      console.log(data.district_id)
      setShowIndiaChart(false);
      setShowStateChart(false);
      setDistrict(data.district_name);
      setDistrictId(data.district_id);
      setShowDistrictChart(true);
    }
    
  }
  
return (
  <div className="" >
    <TopNav/>
    <div className="row wrapper flex-column flex-sm-row" style={{paddingBottom: "11vh", paddingRight:"0px", paddingLeft:"0px", margin:"0px"}} >
        {/* <SideNav/> */}
        <div className=" container col bg-faded "  >
          {/* <div className=" mt-3">
            <button onClick={()=>{setShowIndiaChart(true)}}  className="btn btn-outline-primary col-lg-3 col-md-3 col-sm-12" style={{ margin: "0 auto"}}>India</button>
          </div> */}
          <Dropdown chooseRegionFunction={chooseRegion} showIndiaChartFunction={setShowIndiaChart} />
          {(showIndiaChart) ?
          (
            <div className="col-lg-8 col-md-12 col-sm-12" style={{margin: "0  auto"}}  >
            <h2 style={{textAlign: "center", marginTop:"10px"}}>India </h2>
            <Chart maxY={500000} 
                url="https://api.sutra-india.in/api/plotData/detectedNewInfections/country"/>
            </div>
          )
          : showStateChart ?
          (
            <div className="col-lg-8 col-md-12 col-sm-12" style={{margin: "0  auto"}}  >
            <h2 style={{textAlign: "center"}} >{state} </h2>
                <Chart maxY={25000} url={`https://api.sutra-india.in/api/plotData/detectedNewInfections/state?stateId=${stateId}`} />
            </div>
          )
          // :
          //   showDistrictChart ?
          // (
          //   <div className="col-lg-8 col-md-12 col-sm-12" style={{margin: "0  auto"}}  >
          //   <h2 style={{textAlign: "center"}} > {district} </h2>
          //         <Chart maxY={3000} url={`https://api.sutra-india.in/api/plotData/detectedNewInfections/district?districtId=${districtId}`}/>
          //   </div>
          // )
          :<div/> 
          }
        </div>
        {/* <Footer/> */}
    </div>
  </div>
);
};
export default Landing;
